<template>
  <section id="classroom">
    <b-row class="mb-2">
      <b-col lg="12">
        <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
          <template #tabs-start>
            <b-nav-item
              v-for="(navtab, index) in navTabs"
              :key="index"
              :active="currentTab === index"
              class="font-weight-bold"
              @click="currentTab = index"
            >
              <span class="d-none d-md-block">{{ navtab }}</span>
            </b-nav-item>
          </template>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row v-if="loadExam">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner variant="primary" class="mr-1" />
          <p class="mt-1">
            Memuat Daftar Ujian
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!loadExam && filteredExam.length > 0">
      <b-col v-for="exam in filteredExam" :key="exam" lg="12">
        <b-card class="material-card mb-1">
          <div class="d-flex">
            <div class="mr-1">
              <b-avatar rounded="lg" variant="light-primary">
                <i class="card-icon ti ti-clipboard-list "></i>
              </b-avatar>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column ">
                  <div v-if="exam.status === 'ongoing'">
                    <b-badge variant="light-danger" class="small-gap-bottom">
                      <b-spinner small type="grow" variant="danger"></b-spinner>
                      Berlangsung
                    </b-badge>
                  </div>
                  <div v-if="exam.status === 'done'">
                    <b-badge
                      variant="light-success"
                      class="small-gap-bottom small-gap-right"
                    >
                      <i class="ti ti-check"></i>
                      Selesai
                    </b-badge>
                    <b-badge variant="light-success" class="small-gap-bottom">
                      Nilai : 100
                    </b-badge>
                  </div>
                  <b-card-title class="font-weight-bolder small-gap-bottom">
                    {{ exam.title }}
                  </b-card-title>
                </div>
                <div>
                  <b-button
                    v-if="exam.status === 'ongoing'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    :to="{
                      name: checkAuthorizeRole(
                        'classroom-detail-task-submission'
                      ),
                      params: {
                        id: '78876c0d-6b80-421d-ada3-3cc77c188318',
                        contentId: '69b6f2c4-7af6-4a53-aa70-dc2f6129dc30',
                        taskId: 'c055dce8-5d4d-45b6-b744-c854e9ec7c2c',
                      },
                    }"
                  >
                    <span class="align-middle mr-1">Lihat Ujian</span>
                    <i class="align-middle ti ti-arrow-right"></i>
                  </b-button>

                  <b-button
                    v-if="exam.status === 'done'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                  >
                    <span class="align-middle mr-1">Lihat Ujian</span>
                    <i class="align-middle ti ti-arrow-right"></i>
                  </b-button>
                </div>
              </div>

              <b-card-text>
                Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat.
                Morbi in orci risus. Donec pretium fringilla blandit. Etiam ut
                accumsan leo. Aliquam id mi quam. Vivamus dictum ut erat nec
                congue. Etiam facilisis lacus ut arcu vulputate, non
                pellentesque sem convallis. Proin tempus sapien nisl, nec varius
                risus tristique a.
              </b-card-text>

              <span class="text-muted">
                Dibuat pada 12/12/2022
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!loadExam && filteredExam.length === 0">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center pt-2">
          <b-img class="illustration-small" fluid :src="emptyState" />
          <p class="mb-2">
            Anda belum bergabung kelas manapun.
          </p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            @click="currentTab = 0"
            >Gabung Kelas Baru</b-button
          >
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BNavItem,
  BSpinner,
  BButton,
  BImg,
  BCard,
  BCardText,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BNavItem,
    BSpinner,
    BButton,
    BImg,
    BCard,
    BCardText,
    BAvatar,
    BBadge,
  },
  data() {
    return {
      loadExam: false,
      emptyState: require('@/assets/images/illustration/empty-classroom-state.svg'),
      currentTab: 0,
      navTabs: ['Sedang Berlangsung', 'Akan Berlangsung', 'Selesai'],
      allExams: [
        {
          id: '9123ajso12-3e58-4e8c-ab5e-88da56a3a75e',
          title: 'UTS Geografi',
          desc:
            'Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium fringilla blandit. Etiam ut accumsan leo. Aliquam id mi quam. Vivamus dictum ut erat nec congue. Etiam facilisis lacus ut arcu vulputate, non pellentesque sem convallis. Proin tempus sapien nisl, nec varius risus tristique a. ',
          status: 'done',
          grade: 100,
        },
        {
          id: '71238122asd-3e58-4e8c-ab5e-88da56a3a75e',
          title: 'UAS Geografi',
          desc:
            'Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium fringilla blandit. Etiam ut accumsan leo. Aliquam id mi quam. Vivamus dictum ut erat nec congue. Etiam facilisis lacus ut arcu vulputate, non pellentesque sem convallis. Proin tempus sapien nisl, nec varius risus tristique a. ',
          status: 'ongoing',
          grade: 0,
        },
      ],
    }
  },
  computed: {
    filteredExam() {
      const tempExams = this.allExams
      return tempExams.filter((exam) => {
        if (this.currentTab === 2) {
          return exam.status === 'done'
        }
        return exam.status === 'ongoing'
      })
    },
  },
  methods: {
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
.subject-icon {
  font-size: 2rem;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}
</style>
